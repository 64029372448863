import React from 'react'
import {useStaticQuery, graphql} from 'gatsby'
import SpecialEvent from './BusinessIncentive'

import Container from '../../container'
import SideBar from '../../SideBar'
import Footer from '../../Footer'
import styles from '../page.module.css'

const Page = ({title, _rawBody, teaserImage}) => {
  const data = useStaticQuery(graphql`
    {
    sanitySideBar(id: {eq: "457c3233-be81-5f54-9e18-dc6e2ffba390"}) {
      title
      links {
        _key
        title
        siteLink
      }
    }
  }
  `)

  return (
    <>

      <article className={styles.root}>

        <Container>
          <div className={styles.pageTitleWrapper}>
            <h1 className={styles.pageTitle}>{title}</h1>
          </div>
          <div>
            <div className={styles.mainContent}>

              <div className={styles.innerMainContent}>
                <SpecialEvent _rawBody={_rawBody} teaserImage={teaserImage} />
              </div>

              {data.sanitySideBar && (<SideBar data={data.sanitySideBar} />)}

            </div>
          </div>
        </Container>
      </article>

      <Footer topBorder />

    </>
  )
}
export default Page
